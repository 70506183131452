<template>
  <div class="animated fadeIn">
    <b-card>
      <loading :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"></loading>
      <b-table :small="true" :items="results" :fields="columns">
        <template slot="isAccepted" slot-scope="data">
            <b-badge variant="success" v-if="data.item.isAccepted == 'YES'">Тийм</b-badge>
              <b-badge variant="danger" v-else>Үгүй</b-badge>
          </template>
          <template slot="date" slot-scope="data">
            {{ data.item.year }}/{{ data.item.month }}/{{ data.item.day}}
          </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="10"
        :per-page="10"
        aria-controls="my-table">
      </b-pagination>
    </b-card>
  </div>
</template>
<script>

export default {
  name: 'operation-dashboard',
  components: { },
  data: function () {
    return {
      isLoading: false,
      currentPage: 1,
      results: [],
      columns: [
        { label: 'Овог', key: 'lastname'},
        { label: 'Нэр', key: 'firstname'},
        { label: 'РД', key: 'registerNumber'},
        { label: 'Date', key: 'date'},
        { label: 'Гэрээ байгуулах боломжтой', key: 'isAccepted',class: "text-center"},
        { label: 'Утас', key: 'phone'},
      ],
      registers : []
    }
  },
  created() {
      this.loadData();
  },
  methods: {
    loadData: function() {
      this.$data.isLoading = true
      this.$http.get(this.$config.PARSE.URL + "Users",
      {
          params: {
            limit: 100
          },
          headers: {
            'x-parse-master-key': this.$config.PARSE.KEY,
            'x-parse-application-id': this.$config.PARSE.APP_ID,
          },
          emulateJSON: true
      }
      ).then(response => {
        this.$data.isLoading = false
        if(response.status == 200) {
          this.$data.isLoading = false
          this.$data.results = response.body.results
          var newData = []
          for(var i in this.$data.results) {
            this.$data.registers.push(this.$data.results[i].registerNumber)
          }
          this.getOtherData()
        }
      }, response => {
          this.$data.isLoading = false
      })
    },
    getOtherData: function(){
      this.$data.isLoading = true
      this.$http.post(this.$config.API_URL + "CustomerWebService/get_customer_data_by_registers",
        {
          data: JSON.stringify(this.$data.registers)
        },
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        }
      ).then(response =>{
        this.$data.isLoading = false
        for(var i in response.body.data){
          var item = response.body.data[i]
          this.$data.results[i].firstname = item.firstname
          this.$data.results[i].lastname = item.lastname
          this.$data.results[i].phone = item.phone
        }
      },response => {

      })
    }
  }
}
</script>
